import { BaseQueryApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import store, { RootState } from '../store';
import authSlice from '../features/auth.slice';
import { convertQueryStringToObject } from '@/helpers';
import { Environment } from '@/constants';
import { createApp } from '@shopify/app-bridge';
import { getSessionToken } from '@shopify/app-bridge-utils';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/s/api/`;

const customBaseQuery = () => {
  const baseQuery = fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: async (headers) => {
      const storedSessionToken = (store.getState() as RootState).auth.session.token;
      if (storedSessionToken) {
        if (Date.now() > (store.getState() as RootState).auth.session.expiredTime) {
          try {
            const configApp = {
              apiKey: process.env.REACT_APP_API_KEY || '',
              host:
                new URLSearchParams(window.location.search).get('host') ||
                window
                  .btoa('admin.shopify.com/store/' + (store.getState() as RootState).auth.data.token?.shop.split('.')[0])
                  .replaceAll('=', ''),
              forceRedirect: process.env.REACT_APP_ENV === Environment.Production,
            };
            const app = createApp(configApp);
            const sessionToken = await getSessionToken(app);
            store.dispatch(authSlice.actions.handleGetSessionToken({ token: sessionToken, expiredTime: Date.now() + 60 * 1000 }));
            headers.set('authorization', `Bearer ${sessionToken}`);
          } catch (error) {
            console.log('Error when get session token', error);
          }
        } else {
          headers.set('authorization', `Bearer ${storedSessionToken}`);
        }
      }
      return headers;
    },
  });
  return async (args: string | FetchArgs, api: BaseQueryApi, extraOptions: {}) => {
    const response = await baseQuery(args, api, extraOptions);
    if (response.error?.status === 401) {
      const search = convertQueryStringToObject(window.location.search);
      const dispatch = store.dispatch;
      if (
        !search?.shop ||
        (search?.shop && store.getState().auth.data.token.shop && search?.shop === store.getState().auth.data.token.shop)
      ) {
        dispatch(authSlice.actions.handleAuth({ ...store.getState().auth.data, token: null, isAuth: false }));
      }
    }
    return response;
  };
};

export default customBaseQuery;
